import React from "react";
import SingleChoiceDropdown from "./SingleChoiceDropdown";

class AmountForm extends React.Component {
  constructor(props) {
    super(props);
    this.amountInputRef = React.createRef(); // Create a reference for the input element
  }

  componentDidUpdate(prevProps) {
    // Check if props.amount changed and it's an empty string, then focus on the input
    if (this.props.amount !== prevProps.amount && this.props.amount === "") {
      this.amountInputRef.current.focus();
    }
  }

  render() {
    const { handleInputChange, formData, toggleValue, amount } = this.props; // Destructure props

    return (
      <>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="Amount">
              {this.props.amount ? "Amount" : "Other Amount"}{" "}
              <span className="required">*</span>
            </label>
            <div className="input-group">
              <input
                type="number"
                defaultValue={amount}
                className="form-control"
                id="amount"
                placeholder="Amount"
                onChange={handleInputChange}
                required
                step="0.01" // Allow decimal numbers with two decimal places
                ref={this.amountInputRef} // Assign the reference to the input element
                min="0"
              />
              <span className="input-group-addon">
                <i className="fas fa-dollar-sign"></i>
              </span>
            </div>
          </div>
        </div>


      </>
    );
  }
}

export default AmountForm;
