import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes } from "react-router-dom";
import DonationComponent from './components/DonationComponent'
import Placeholder from './components/Placeholder';
import MarleyDonationComponent from './components/MarleyDonationComponent';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<DonationComponent />} />
        <Route path="/preview" element={<DonationComponent />} />
        <Route path="/marley" element={<MarleyDonationComponent />} />
      </Routes>
    </div>
  );
}

export default App;
